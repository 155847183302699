import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import classNames from "classnames"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
// import Projects from "../components/Sections/Projects/Projects"
import styles from "./project.module.scss"

export const query = graphql`
  query MyQuery($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      title
      subtitle
      heroBackgroundColor
      headerImagePositioning
      heroImage {
        file {
          url
        }
        title
      }
      images {
        title,
        description,
        file {
          url
        }
      }
      overview {
        overview
      }
      tags
      content {
        content
      }
    }
  }
`

export default ({ data, pageContext, location }) => {

  const project = data.contentfulProject;

  const Content = () => {
    return (
      <section className={styles.contentSection}>
        {project.content && (
          <div className={classNames(styles.contentWrapper)}>
            <div className="row">
              <div class="col-md-8 offset-md-2">
                <ReactMarkdown source={project.content.content} />
              </div>
            </div>
          </div>
        )}
        {project.images && (
          <div className={styles.imageWrapper}>
            {project.images.map(image => (
              <div className={styles.imagesImg}>
                {image.file.url.includes(".mov") ? 
                <video height="500px" width="auto" autoplay loop muted controls>
                  <source src={image.file.url} type="video/mp4" />
                </video> : <img src={image.file.url} alt={image.title} />}
                <span><strong>{image.title}</strong><br/><br/>{image.description && image.description}</span>
              </div>
            ))}
          </div>
        )}
      </section>
    )
  }

  return (
    <Layout mainClassName={styles.project}>
      <SEO title={project.title} />
      <section className={styles.header}>
        <div className={styles.contentWrapper}>
          <a href="/portfolio" className="icon ion-ios-arrow-back">
            Portfolio
          </a>
          <h1>{project.title}</h1>
          <h3 className={styles.subtitle}>{project.subtitle}</h3>
        </div>
        {project.heroImage && (
          <div
            className={styles.imageWrapper}
            style={{
              backgroundColor: `${project.heroBackgroundColor}`,
              justifyContent: `${project.headerImagePositioning}`,
            }}
          >
            <img
              src={project.heroImage.file.url}
              alt={project.heroImage.title}
            />
          </div>
        )}
      </section>
      <section className={styles.subheader}>
        <div className={classNames(styles.contentWrapper)}>
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <ReactMarkdown source={project.overview.overview} />
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 offset-md-2">
              {project.tags && <h3>Project Role</h3>}
              {project.tags.map(tag => (
                <span className="tag">{tag}</span>
              ))}
            </div>
          </div>
        </div>
      </section>
      <Content />
      <div className={styles.preFooter}>
        <a href="/portfolio" className="icon ion-ios-arrow-back">
          Portfolio
        </a>
      </div>
      {/* <Projects sectionTitle="More projects" columns={3} alignTop /> */}
    </Layout>
  )
}
